<template>
  <div class="google-ads-mockup-generator">
    <div class="mockup-name">
      <router-link :to="`/google-ads-mockup-group/${$route.query.gId}`">
        <feather-icon
          icon="ChevronLeftIcon"
          size="24"
        />
      </router-link>
      {{ mockupName }}
    </div>
    <div
      class="mockup"
      :class="{ 'justify-content-end': isNewCreate }"
    >
      <div
        v-if="!isNewCreate"
        class="mockup-action gap-4"
      >
        <div class="mockup-action-item revision-action col-gap-4">
          <HeroVueSelect
            id="revisions"
            v-model="currentRevision"
            label="Revisions"
            :loading="revision.isLoading"
            :options="revision.options"
            :clearable="false"
            class="select-revision"
          />
          <b-button
            variant="primary"
            type="button"
            @click="handleLoadFormData"
          >
            Load
          </b-button>
        </div>
        <template v-if="isAuthenticated">
          <div class="mockup-action-item">
            <b-button
              v-b-tooltip.hover="isCopied ? 'Copied' : 'Copy'"
              variant="outline-primary"
              type="button"
              @click="copyLink"
            >
              <feather-icon icon="GlobeIcon" />
              Copy Link
            </b-button>
          </div>
          <div
            v-if="!isLatestRevision"
            class="mockup-action-item"
          >
            <b-button
              :variant="`outline-${isFormDuplicating ? 'danger' : 'primary'}`"
              type="button"
              @click="isFormDuplicating = !isFormDuplicating"
            >
              <feather-icon icon="CopyIcon" />
              {{ isFormDuplicating ? 'Cancel Duplicate' : 'Duplicate' }}
            </b-button>
          </div>
          <div class="mockup-action-item">
            <b-button
              variant="outline-primary"
              type="button"
              @click="exportGoogleAdsMockup"
            >
              <b-spinner v-if="isFormExporting" small />
              <feather-icon v-else icon="DownloadIcon" />
              Export
            </b-button>
          </div>
        </template>
      </div>
      <b-button
        variant="primary"
        type="button"
        :disabled="isFormDisabled"
        @click="handleOpenConfirmGoogleAdsMockupFormModal"
      >
        Save
      </b-button>
    </div>
    <div class="line-separator" />
    <div>
      <b-row>
        <b-col cols="12" lg="6" order="1" order-lg="0" class="mt-1 mt-lg-0">
          <GoogleAdsMockupInput
            id="final-url"
            v-model="finalUrl"
            type="input"
            label="Final URL"
            label-width="126px"
            :disabled="isFormDisabled"
          />
          <div class="d-flex align-items-center col-gap-2 display-path">
            <GoogleAdsMockupInput
              id="path1"
              v-model="displayPath.path1"
              type="input"
              label="Display Paths"
              label-width="126px"
              :disabled="isFormDisabled"
              :limit="15"
            />
            <div class="slash">
              /
            </div>
            <GoogleAdsMockupInput
              id="path2"
              v-model="displayPath.path2"
              label=""
              type="input"
              :disabled="isFormDisabled"
              :limit="15"
            />
          </div>
          <div
            v-for="form in forms"
            :key="form.title"
            class="collapse-wrapper"
          >
            <div
              v-b-toggle="`collapse-${form.id}`"
              class="collapse-header"
            >
              <div class="title">
                {{ form.title }}
              </div>
              <div class="action">
                <b-dropdown
                  v-if="form.id === 'site-links'"
                  class="dropdown-site-link"
                  variant="outline-primary"
                  right
                  no-caret
                >
                  <template #button-content>
                    Preview {{ siteLinkPreview }}
                    <feather-icon icon="MonitorIcon" />
                  </template>
                  <template v-for="preview in 3">
                    <b-dropdown-item
                      :key="preview"
                      :active="siteLinkPreview === preview"
                      @click.stop="siteLinkPreview = preview"
                    >
                      Preview {{ preview }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <feather-icon icon="ChevronDownIcon" />
              </div>
            </div>
            <b-collapse
              :id="`collapse-${form.id}`"
              :visible="form.collapse"
              class="collapse-body"
            >
              <div
                v-if="form.colFieldIds.length"
                class="d-flex align-items-center col-gap-4 field-item"
              >
                <GoogleAdsMockupInput
                  v-for="field in getRenderColFields(form)"
                  :id="`form-${form.id}-field-${field.id}`"
                  :key="field.id"
                  v-model="field.data"
                  :type="field.type"
                  :options="field.options"
                  :label="field.label"
                  :label-width="form.labelWidth"
                  :placeholder="field.placeholder"
                  :disabled="isFormDisabled"
                  :limit="form.limit"
                  :pin-positions="form.pinPositions"
                  @select="setHeaderTypeOptions($event, field.id); updatePreviewPreset(form.id)"
                />
              </div>
              <template v-for="field in getRenderFields(form)">
                <GoogleAdsMockupInput
                  :id="`form-${form.id}-field-${field.id}`"
                  :key="`form-${form.id}-field-${field.id}`"
                  v-model="field.data"
                  :type="field.type"
                  :options="field.options"
                  :label="field.label"
                  :label-width="form.labelWidth"
                  :disabled="isFormDisabled"
                  :limit="form.limit"
                  :pin-positions="form.pinPositions"
                  class="field-item"
                  @blur="updatePreviewPreset(form.id)"
                  @pin-change="updatePreviewPreset(form.id)"
                />
                <GoogleAdsMockupInput
                  v-for="subField in field.subFields"
                  :id="`form-${form.id}-field-${field.id}-sub-${subField.id}`"
                  :key="`form-${form.id}-field-${field.id}-sub-${subField.id}`"
                  v-model="subField.data"
                  :type="subField.type"
                  :options="subField.options"
                  :label="subField.label"
                  :label-width="form.labelWidth"
                  :disabled="isFormDisabled"
                  :limit="subField.limit"
                  :pin-positions="subField.pinPositions"
                  class="field-item sub-field-item"
                  @blur="updatePreviewPreset(form.id)"
                />
              </template>
              <div
                v-if="form.displayFields < form.maxFields"
                class="d-flex align-items-center col-gap-2"
              >
                <b-button
                  v-if="!isFormDisabled && form.fields.slice(form.displayFields + form.colFieldIds.length).some(field => !field.isDisplaying)"
                  variant="outline-primary"
                  type="button"
                  class="mt-1"
                  @click="handleAddMoreItem(form.id)"
                >
                  <feather-icon icon="PlusIcon" />
                  Add
                </b-button>
                <b-button
                  v-if="!isFormDisabled && form.fields.slice(form.displayFields + form.colFieldIds.length).some(field => field.isDisplaying)"
                  variant="outline-danger"
                  type="button"
                  class="mt-1"
                  @click="handleRemoveItem(form.id)"
                >
                  <feather-icon icon="XIcon" />
                  Remove
                </b-button>
              </div>
            </b-collapse>
          </div>
        </b-col>
        <b-col cols="12" lg="6" order="0" order-lg="1" class="col-preview">
          <GoogleAdsMockupPreview
            :final-url="finalUrl"
            :display-path="displayPath"
            :preview-preset="previewPreset"
            :site-link-preview="siteLinkPreview"
          />
        </b-col>
      </b-row>
    </div>
    <ConfirmGoogleAdsMockupForm
      :name="formData ? formData.latestGoogleAdsMockup.name : ''"
      :remark="formData ? formData.latestGoogleAdsMockup.remark : ''"
      @confirm="handleSaveForm"
    />
  </div>
</template>

<script>
import GoogleAdsMockupInput from '@/views/google-ads-mockup/components/GoogleAdsMockupInput.vue'
import GoogleAdsMockupPreview from '@/views/google-ads-mockup/components/GoogleAdsMockupPreview.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import ConfirmGoogleAdsMockupForm from '@/views/google-ads-mockup/components/ConfirmGoogleAdsMockupForm.vue'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import { BSpinner } from 'bootstrap-vue'

const headerLanguageOptions = [
  {
    text: 'Thai',
    value: 'Thai',
  },
  {
    text: 'English',
    value: 'English',
  },
]
const headerType = {
  thOptions: [
    {
      text: 'ความคุ้มครองในประกัน',
      value: 'ความคุ้มครองในประกัน',
    },
    {
      text: 'โชว์',
      value: 'โชว์',
    },
    {
      text: 'บริการ',
      value: 'บริการ',
    },
    {
      text: 'แบรนด์',
      value: 'แบรนด์',
    },
    {
      text: 'ประเภท',
      value: 'ประเภท',
    },
    {
      text: 'ปลายทาง',
      value: 'ปลายทาง',
    },
    {
      text: 'ย่านใกล้เคียง',
      value: 'ย่านใกล้เคียง',
    },
    {
      text: 'รุ่น',
      value: 'รุ่น',
    },
    {
      text: 'โรงเรมเด่น',
      value: 'โรงเรมเด่น',
    },
    {
      text: 'สไตล์',
      value: 'สไตล์',
    },
    {
      text: 'สิ่งอำนวยความสะดวก',
      value: 'สิ่งอำนวยความสะดวก',
    },
    {
      text: 'หลักสูตร',
      value: 'หลักสูตร',
    },
    {
      text: 'หลักสูตรปริญญา',
      value: 'หลักสูตรปริญญา',
    },
  ],
  enOptions: [
    {
      text: 'Amenities',
      value: 'Amenities',
    },
    {
      text: 'Brands',
      value: 'Brands',
    },
    {
      text: 'Courses',
      value: 'Courses',
    },
    {
      text: 'Degree programs',
      value: 'Degree programs',
    },
    {
      text: 'Destinations',
      value: 'Destinations',
    },
    {
      text: 'Featured hotels',
      value: 'Featured hotels',
    },
    {
      text: 'Insurance coverage',
      value: 'Insurance coverage',
    },
    {
      text: 'Models',
      value: 'Models',
    },
    {
      text: 'Neighborhoods',
      value: 'Neighborhoods',
    },
    {
      text: 'Service catalog',
      value: 'Service catalog',
    },
    {
      text: 'Shows',
      value: 'Shows',
    },
    {
      text: 'Styles',
      value: 'Styles',
    },
    {
      text: 'Types',
      value: 'Types',
    },
  ],
}

export default {
  name: 'GoogleAdsMockupDetail',
  components: {
    BSpinner,
    ConfirmGoogleAdsMockupForm,
    HeroVueSelect,
    GoogleAdsMockupPreview,
    GoogleAdsMockupInput,
  },
  data() {
    return {
      isFormLoaded: false,
      isFormDuplicating: false,
      isFormExporting: false,
      isCopied: false,
      currentRevision: '',
      revision: {
        options: [],
        isLoading: false,
      },
      siteLinkPreview: 1,
      previewPreset: {
        headlines: [],
        descriptions: [],
        callouts: [],
        structuredSnippets: [],
        siteLinks: [],
      },
      finalUrl: { value: '' },
      displayPath: {
        path1: { value: '' },
        path2: { value: '' },
      },
      formData: null,
      forms: [
        {
          id: 'headlines',
          key: 'headline',
          title: 'Headlines',
          limit: 30,
          pinPositions: [1, 2, 3],
          collapse: true,
          colFieldIds: [],
          maxFields: 15,
          displayFields: 15,
          maxPreviews: 3,
          label: 'Headline',
          labelWidth: '112px',
          type: 'input',
          defaultData: {
            value: '',
            pin: 0,
          },
          fields: [],
        },
        {
          id: 'descriptions',
          key: 'description',
          title: 'Descriptions',
          limit: 90,
          pinPositions: [1, 2],
          collapse: true,
          colFieldIds: [],
          maxFields: 4,
          displayFields: 4,
          maxPreviews: 3,
          label: 'Description',
          labelWidth: '125px',
          type: 'input',
          defaultData: {
            value: '',
            pin: 0,
          },
          fields: [],
        },
        {
          id: 'callouts',
          key: 'callout_text',
          title: 'Callouts',
          limit: 25,
          pinPositions: [],
          collapse: true,
          colFieldIds: [],
          maxFields: 10,
          displayFields: 4,
          maxPreviews: 4,
          label: 'Callout',
          labelWidth: '93px',
          type: 'input',
          defaultData: {
            value: '',
          },
          fields: [],
        },
        {
          id: 'structured-snippets',
          key: 'structured_snippet_value',
          title: 'Structured Snippets',
          limit: 25,
          pinPositions: [],
          collapse: true,
          colFieldIds: ['header-language', 'header-type'],
          maxFields: 10,
          displayFields: 4,
          maxPreviews: 4,
          label: 'Value',
          labelWidth: '82px',
          type: 'input',
          defaultData: {
            value: '',
          },
          fields: [
            {
              id: 'header-language',
              key: 'structured_snippet_header_language',
              label: 'Header',
              type: 'select',
              options: headerLanguageOptions,
              data: {
                value: '',
                isValueChanged: false,
              },
              subFields: [],
            },
            {
              id: 'header-type',
              key: 'structured_snippet_header_type',
              label: '',
              placeholder: 'Select header type',
              type: 'select',
              options: [],
              data: {
                value: '',
                isValueChanged: false,
              },
              subFields: [],
            },
          ],
        },
        {
          id: 'site-links',
          key: 'sitelink_text',
          title: 'Sitelinks',
          limit: 25,
          pinPositions: [],
          collapse: true,
          colFieldIds: [],
          maxFields: 10,
          displayFields: 4,
          maxPreviews: 4,
          label: 'Sitelink',
          labelWidth: '124px',
          type: 'input',
          defaultData: {
            value: '',
          },
          subFieldConfigs: [
            {
              key: 'sitelink_text_{id}_description_{index}',
              limit: 35,
              pinPositions: [],
              maxFields: 2,
              label: 'Description',
              type: 'input',
            },
            {
              key: 'sitelink_final_url_{id}',
              limit: 0,
              pinPositions: [],
              maxFields: 1,
              label: 'Final URL',
              type: 'input',
            },
          ],
          fields: [],
        },
      ],
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['heroAiAuthentications/isAuthenticated']
    },

    isNewCreate() {
      return !this.$route.params.id
    },

    isFormDisabled() {
      if (this.formData) {
        if (this.isFormDuplicating) return false

        const { latestGoogleAdsMockup } = this.formData
        return this.revision.options.findIndex(revisionOption => revisionOption.value === latestGoogleAdsMockup.created_at) !== 0
      }
      return false
    },

    isLatestRevision() {
      if (this.formData) {
        const { latestGoogleAdsMockup } = this.formData
        return this.revision.options[0]?.value === latestGoogleAdsMockup.created_at
      }
      return true
    },

    mockupName() {
      if (this.isNewCreate) {
        return 'Create New Mockup'
      }

      if (this.formData) {
        return this.formData.latestGoogleAdsMockup.name
      }

      return 'Mockup Name'
    },
  },
  created() {
    this.forms.forEach(form => {
      // eslint-disable-next-line no-plusplus
      for (let fieldIndex = 1; fieldIndex <= form.maxFields; fieldIndex++) {
        const field = {
          id: fieldIndex,
          key: `${form.key}_${fieldIndex}`,
          label: `${form.label} ${fieldIndex}`,
          type: form.type,
          ...form.type === 'select' && { options: [] },
          isDisplaying: fieldIndex <= form.displayFields,
          data: {
            ...form.defaultData,
            isValueChanged: false,
            ...form.pinPositions.length && { isPinChanged: false },
          },
          subFields: [],
        }

        if (form.subFieldConfigs?.length) {
          let subFieldId = 1
          form.subFieldConfigs.forEach(subFieldConfig => {
            // eslint-disable-next-line no-plusplus
            for (let subFieldIndex = 1; subFieldIndex <= subFieldConfig.maxFields; subFieldIndex++) {
              field.subFields.push({
                id: subFieldId,
                key: subFieldConfig.key.replace('{id}', fieldIndex).replace('{index}', subFieldId),
                label: `${subFieldConfig.label}${subFieldConfig.maxFields > 1 ? ` ${subFieldIndex}` : ''}`,
                type: subFieldConfig.type,
                limit: subFieldConfig.limit,
                pinPositions: subFieldConfig.pinPositions,
                data: {
                  ...form.defaultData,
                  isValueChanged: false,
                },
              })
              subFieldId += 1
            }
          })
        }

        form.fields.push(field)
      }
    })
  },
  mounted() {
    if (!this.isNewCreate) {
      this.initialize()
    }
  },
  methods: {
    async initialize() {
      this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are preparing mockup generator.' })

      await this.getRevisionDropdownOptions()
      await this.getGoogleAdsMockup()

      if (this.$route.query.mode === 'duplicating') {
        this.isFormDuplicating = true
      }

      this.$swal().close()
    },

    handleLoadFormData() {
      const { query, params } = this.$route

      this.getGoogleAdsMockup()

      if (query.createdAt !== this.currentRevision) {
        this.$router.replace(`/google-ads-mockup-generator/${params.id}?gId=${query.gId}&createdAt=${this.currentRevision}`)
      }
    },

    async getRevisionDropdownOptions() {
      const { query, params } = this.$route

      try {
        this.revision.isLoading = true

        const url = `/dropdown-options/revision-options/${query.gId}/mockups/${params.id}/client`
        const response = await axiosInstance.get(url)

        this.revision.options = response.data.data.map(revisionOption => ({
          ...revisionOption,
          text: revisionOption.created_at,
          value: revisionOption.created_at,
        }))

        this.currentRevision = query.createdAt || this.revision.options[0].created_at
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.revision.isLoading = false
      }
    },

    async getGoogleAdsMockup() {
      try {
        if (this.isFormLoaded) {
          this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are preparing mockup generator.' })
        }

        if (this.isFormDuplicating) {
          this.isFormDuplicating = false
        }

        const { query, params } = this.$route

        const url = `/google-ads-mockups/groups/${query.gId}/mockups/${params.id}/client`
        const response = await axiosInstance.get(url, { params: { created_at: this.currentRevision } })

        const { latestGoogleAdsMockup, previousGoogleAdsMockup } = response.data.data

        if (!latestGoogleAdsMockup) {
          await this.$swal({ ...SweetAlert.error, text: 'Google ads mockup does not exist.' })
          await this.$router.replace(`/google-ads-mockup-group/${query.gId}`)
          return
        }

        if (latestGoogleAdsMockup) {
          this.formData = response.data.data
        }

        let tempHeaderLanguage = ''
        let headerTypeOptions = []

        this.finalUrl.value = latestGoogleAdsMockup.final_url || ''
        this.displayPath.path1.value = latestGoogleAdsMockup.path_1 || ''
        this.displayPath.path2.value = latestGoogleAdsMockup.path_2 || ''

        /* Start map response value to forms */
        this.forms = this.forms.map(form => ({
          ...form,
          fields: form.fields.map((field, fieldIndex) => {
            if (field.id === 'header-language') {
              tempHeaderLanguage = latestGoogleAdsMockup[field.key] || ''
              if (tempHeaderLanguage) {
                headerTypeOptions = tempHeaderLanguage === 'Thai' ? headerType.thOptions : headerType.enOptions
              }
            }

            const hasSubFieldValue = field.subFields.some(subField => !!latestGoogleAdsMockup[subField.key])

            return {
              ...field,
              isDisplaying: fieldIndex < form.displayFields || !!latestGoogleAdsMockup[field.key] || hasSubFieldValue,
              data: {
                ...field.data,
                value: latestGoogleAdsMockup[field.key] || '',
                isValueChanged: previousGoogleAdsMockup ? previousGoogleAdsMockup[field.key] !== latestGoogleAdsMockup[field.key] : false,
                ...form.pinPositions?.length && {
                  pin: latestGoogleAdsMockup[`${field.key}_position`] || 0,
                  isPinChanged: previousGoogleAdsMockup ? previousGoogleAdsMockup[`${field.key}_position`] !== latestGoogleAdsMockup[`${field.key}_position`] : false,
                },
              },
              ...field.id === 'header-type' && {
                options: headerTypeOptions,
              },
              subFields: field.subFields.map(subField => ({
                ...subField,
                data: {
                  ...subField.data,
                  value: latestGoogleAdsMockup[subField.key] || '',
                  isValueChanged: previousGoogleAdsMockup ? previousGoogleAdsMockup[subField.key] !== latestGoogleAdsMockup[subField.key] : false,
                },
              })),
            }
          }),
        }))

        this.forms = this.forms.map(form => {
          const lastFieldDisplayedIndex = form.fields.findLastIndex(field => field.isDisplaying)

          return {
            ...form,
            fields: form.fields.map((field, fieldIndex) => ({
              ...field,
              isDisplaying: fieldIndex <= lastFieldDisplayedIndex,
            })),
          }
        })

        this.forms.forEach(({ id }) => {
          this.updatePreviewPreset(id)
        })
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isFormLoaded = true
        this.$swal().close()
      }
    },

    handleAddMoreItem(formId) {
      const formIndex = this.forms.findIndex(form => form.id === formId)
      const fieldDisplayedIndex = this.forms[formIndex].fields.findIndex(field => !field.isDisplaying)
      this.forms[formIndex].fields[fieldDisplayedIndex].isDisplaying = true

      this.updatePreviewPreset(formId)
    },

    handleRemoveItem(formId) {
      const formIndex = this.forms.findIndex(form => form.id === formId)
      const lastFieldDisplayedIndex = this.forms[formIndex].fields.findLastIndex(field => field.isDisplaying)
      if (lastFieldDisplayedIndex > -1) {
        this.forms[formIndex].fields[lastFieldDisplayedIndex].isDisplaying = false
      }

      this.updatePreviewPreset(formId)
    },

    getRenderColFields(form) {
      return form.fields.filter(field => form.colFieldIds.includes(field.id))
    },

    getRenderFields(form) {
      return form.fields.filter(field => !form.colFieldIds.includes(field.id) && field.isDisplaying)
    },

    async copyLink() {
      this.isCopied = true
      await navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },

    async exportGoogleAdsMockup() {
      try {
        this.isFormExporting = true

        const { query, params } = this.$route

        const url = `/google-ads-mockups/groups/${query.gId}/mockups/${params.id}/export`
        const response = await axiosInstance.get(url, { params: { created_at: query.createdAt } })

        window.open(response.data.data, '_blank')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isFormExporting = false
      }
    },

    setHeaderTypeOptions(value, fieldId) {
      if (value && fieldId === 'header-language') {
        let headerTypeOptions = []
        switch (value) {
          case 'Thai':
            headerTypeOptions = headerType.thOptions
            break
          case 'English':
            headerTypeOptions = headerType.enOptions
            break
          default:
            headerTypeOptions = []
            break
        }

        this.forms = this.forms.map(form => ({
          ...form,
          fields: form.id !== 'structured-snippets' ? form.fields : form.fields.map(field => ({
            ...field,
            data: {
              value: field.id === 'header-type' ? '' : field.data.value,
            },
            ...field.options && { options: field.id === 'header-type' ? [...headerTypeOptions] : field.options },
          })),
        }))
      }
    },

    arrangeByPin(data) {
      let pins = data.map(item => item.data.pin)
      const pinWithoutZeros = [...new Set(pins.filter(pin => pin > 0 && pin < 3))]
      const sumPinWithoutZero = pinWithoutZeros.reduce((acc, pin) => acc + pin, 0)

      let freeValueIndex = 0
      if (pinWithoutZeros.length === 1) {
        if (sumPinWithoutZero === 1) {
          freeValueIndex = 2
        }
        if (sumPinWithoutZero === 2) {
          freeValueIndex = 1
        }
      } else {
        freeValueIndex = sumPinWithoutZero
      }
      pins = pins.map(pin => (pin === 0 ? freeValueIndex : pin))

      const newData = data.map((item, index) => ({
        ...item,
        data: {
          ...item.data,
          pin: pins[index],
        },
      }))

      const uniqueByPin = newData.reduce((acc, item) => {
        const { pin } = item.data
        if (!acc[pin]) {
          acc[pin] = item
        }

        return acc
      }, {})

      const uniqueItems = Object.values(uniqueByPin)

      // Create an array with 3 slots, each representing an index in the final array
      const positions = new Array(uniqueItems.length).fill([])

      // Populate the positions array according to pin values
      newData.forEach(item => {
        const index = item.data.pin - 1

        if (!positions[index]?.length) {
          positions[index] = [item.data.value]
        } else {
          positions[index].push(item.data.value)
        }
      })

      function generateCombinations(arr) {
        if (arr.length === 0) return [[]]
        const first = arr[0]
        const rest = arr.slice(1)

        const combinationsWithoutFirst = generateCombinations(rest)
        const allCombinations = []

        first.forEach(value => {
          combinationsWithoutFirst.forEach(combination => {
            allCombinations.push([value, ...combination])
          })
        })

        return allCombinations
      }

      return generateCombinations(positions)
    },

    updatePreviewPreset(formId) {
      let currentFormId = formId

      const currentForm = this.forms.find(form => form.id === currentFormId)
      let activeFields = currentForm.fields.filter(field => field.data.value !== '' && field.isDisplaying)
      const activePinFields = activeFields.filter(field => field.data.pin)

      let structuredSnippetHeaderType = ''
      if (currentFormId === 'structured-snippets') {
        currentFormId = 'structuredSnippets'
        structuredSnippetHeaderType = activeFields.find(activeField => activeField.id === 'header-type')?.data.value || ''
        activeFields = activeFields.filter(activeField => activeField.id !== 'header-type' && activeField.id !== 'header-language')

        if (!structuredSnippetHeaderType) {
          activeFields = []
        }
      }

      if (currentFormId === 'site-links') {
        currentFormId = 'siteLinks'
      }

      // Reset current preset
      this.previewPreset[currentFormId] = []

      const setPreviewPreset = fieldValues => {
        let mappedFieldValues = fieldValues.map(fieldValue => fieldValue.data.value)

        switch (currentFormId) {
          case 'structuredSnippets':
            this.previewPreset[currentFormId].push([structuredSnippetHeaderType, ...mappedFieldValues])
            break
          case 'siteLinks':
            mappedFieldValues = fieldValues.map(fieldValue => ({
              siteLink: fieldValue.data.value,
              descriptions: fieldValue.subFields.filter(subField => subField.id !== 3).map(subField => subField.data.value),
            }))
            this.previewPreset[currentFormId].push([...mappedFieldValues])
            break
          default:
            this.previewPreset[currentFormId].push([...mappedFieldValues])
            break
        }
      }

      if (activeFields.length === 1) {
        setPreviewPreset([activeFields[0]])
        return
      }

      if (activePinFields.length) {
        this.previewPreset[currentFormId] = this.arrangeByPin(activeFields)
        return
      }

      for (let i = 0; i < activeFields.length; i++) {
        for (let j = 0; j < activeFields.length; j++) {
          if (i !== j) {
            if (activeFields.length >= 3) {
              for (let k = 0; k < activeFields.length; k++) {
                if (i !== k && j !== k) {
                  if (activeFields.length >= 4 && currentForm.maxPreviews === 4) {
                    for (let l = 0; l < activeFields.length; l++) {
                      if (i !== l && j !== l && k !== l) {
                        setPreviewPreset([activeFields[i], activeFields[j], activeFields[k], activeFields[l]])
                      }
                    }
                  } else {
                    setPreviewPreset([activeFields[i], activeFields[j], activeFields[k]])
                  }
                }
              }
            } else {
              setPreviewPreset([activeFields[i], activeFields[j]])
            }
          }
        }
      }
    },

    convertFormsToPayload(confirmForm) {
      return {
        name: confirmForm.name,
        created_by_name: confirmForm.editorName,
        remark: confirmForm.remark,
        final_url: this.finalUrl.value,
        path_1: this.displayPath.path1.value,
        path_2: this.displayPath.path2.value,
        ...this.forms.reduce((acc, item) => ({
          ...acc,
          ...item.fields.reduce((fAcc, fItem) => ({
            ...fAcc,
            [fItem.key]: fItem.isDisplaying ? fItem.data.value : '',
            ...item.pinPositions.length && { [`${fItem.key}_position`]: fItem.data.pin },
            ...fItem.subFields?.reduce((sFAcc, sFItem) => ({
              ...sFAcc,
              [sFItem.key]: fItem.isDisplaying ? sFItem.data.value : '',
            }), {}),
          }), {}),
        }), {}),
      }
    },

    handleOpenConfirmGoogleAdsMockupFormModal() {
      if (this.isFormDisabled) return

      this.$bvModal.show('confirm-google-ads-mockup-form-modal')
    },

    async handleSaveForm(confirmForm) {
      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are saving mockup.' })

        const payload = this.convertFormsToPayload(confirmForm)
        const { query, params } = this.$route

        let response = null
        if (this.isNewCreate) {
          response = await axiosInstance.post(`google-ads-mockups/groups/${query.gId}/mockups`, payload)
        } else {
          response = await axiosInstance.put(`google-ads-mockups/groups/${query.gId}/mockups/${params.id}/client`, payload)
        }

        await this.$router.replace(`/google-ads-mockup-generator/${response.data.data.unique_id}?gId=${response.data.data.google_ads_mockup_group_id}&createdAt=${response.data.data.created_at}`)

        await this.$nextTick()

        this.isFormLoaded = false
        await this.initialize()
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.$swal().close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.line-separator {
  height: 1px;
  background: #D8D6DE;
  margin: 16px 0;
}
.google-ads-mockup-generator {
  background-color: white;
  padding: 16px 10px;
  .mockup-name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
  .mockup {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .mockup-action {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      row-gap: 16px;
      .mockup-action-item {
        &.revision-action {
          display: flex;
          align-items: flex-end;
          .select-revision {
            min-width: 218px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .col-preview {
    background-color: white;
    @media (max-width: 1024px) {
      position: sticky;
      top: 0;
      z-index: 999;
    }
  }
  .display-path {
    margin: 10px 0;
    .input-group {
      &:first-child {
        flex: 1 0 126px;
      }
      &:last-child {
        flex: 1 0 0;
      }
    }
  }
 .collapse-wrapper {
  background-color: white;
  border: 1px solid #EBE9F1;
  border-radius: 4px;
  padding: 0 16px;
  & + .collapse-wrapper {
    margin-top: 10px;
  }
  .collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    .title {
      font-weight: 500;
    }
    .action {
      display: flex;
      align-items: center;
      column-gap: 6px;
      .dropdown-site-link {
        ::v-deep.btn {
          display: flex;
          column-gap: 10px;
          padding: 10px;
        }
      }
    }
  }
  .collapse-body {
    padding-bottom: 16px;
    .field-item + .field-item {
      margin-top: 16px;
    }
    .sub-field-item {
      padding-left: 16px;
    }
  }
}
}
</style>
